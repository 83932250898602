import React from "react";
import { connect } from "react-redux";
import { withRouter } from "./utils/withRouter";
import { intercomMessenger } from "componentlibrary/dist/utils/IntercomMessenger";
/*
 * Imported directly since these components will be required regardless
 * of the state of the application
 */
import {
  handleAuthSession,
  authorize,
  exchangeCodeForTokens,
  syncLanguageConfig,
} from "./reducers/auth";
import { getAppConfig } from "./reducers/appConfig";
import { Button, Spin } from "antd";
import AuthModal from "./Containers/AuthModal";
import ResponsiveLayout from "./Containers/ResponsiveLayout";
import Layout from "./Containers/Layout";
import Loading from "./Components/Loading";
import Config from "./Config";

import "./App.css";

/*
 * Lazy Loaded - only necessary in certain states. The conditional logic in the
 * render function below e.g. {isDataLoaded && <Print />} ensures the component
 * is only requested in certain state, delaying load of code
 */
const Unauthorised = React.lazy(() => import("./Components/Unauthorised"));
const MapContent = React.lazy(() => import("./Components/MapContent"));
const Print = React.lazy(() => import("./Containers/Print"));
const ModalCompanyStructure = React.lazy(() =>
  import("./Components/ModalCompanyStructure")
);

/*
 * Done as a class component rather than a pure function component simply to
 * get access to the componentDidMount() method to fire init() method on first
 * load of application.
 */
class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showError: false,
      currentLanguage: "en",
    };
  }

  componentDidMount() {
    const { appConfig } = this.props;
    if (appConfig && appConfig.maintenanceMode === false) {
      console.log("handle auth");
      this.handleAuth();
    }

    this.getAppConfig();
  }

  componentDidUpdate(prevProps) {
    const { auth, appConfig } = this.props;
    const { showError } = this.state;

    // Only call handleAuth() if the application loads with maintenanceMode
    // turned off or if it was previously on and now it's been turned off.
    if (
      (prevProps.appConfig &&
        prevProps.appConfig.maintenanceMode === true &&
        appConfig.maintenanceMode === false) ||
      (prevProps.appConfig === null &&
        appConfig &&
        appConfig.maintenanceMode === false)
    ) {
      this.handleAuth();
    }

    if (auth.error && !showError) {
      this.handleAuthError(auth.error);
    }
  }

  handleAuthError() {
    this.setState({
      showError: true,
    });
  }

  async getAppConfig() {
    const { getAppConfig } = this.props;
    await getAppConfig();
    this.appConfigInterval = setInterval(getAppConfig.bind(this), 60 * 1000);
  }

  async handleAuth() {
    const { handleAuthSession, exchangeCodeForTokens } = this.props;

    const code = this.getSearchParam("code");
    const state = this.getSearchParam("state");

    // We are not currently going through the authorization flow.
    // Call handleAuthSession to use an existing session or begin a new one.
    if (!code) {
      return handleAuthSession();
    }

    // Auth code flow in progress. Invoke token exchange
    await exchangeCodeForTokens(code, state);

    return this.redirect();
  }

  getSearchParam(param) {
    const { location } = this.props;
    const params = new URLSearchParams(location.search);
    if (params.has(param)) {
      return params.get(param);
    }

    return null;
  }

  redirect() {
    const { history } = this.props;
    const pathname = sessionStorage.getItem("lastPath") || "/";
    sessionStorage.removeItem("lastPath");

    return history.push(pathname);
  }

  get loaderMessage() {
    const { auth } = this.props;

    if (auth.authorizing) {
      return "Authenticating with Forwood ID";
    }

    if (this.getSearchParam("code") !== null) {
      return "Authorizing";
    }

    return null;
  }

  render() {
    const {
      isLoading,
      auth,
      companyId,
      isDataLoaded,
      mapReady,
      appConfig,
      showAuthConfirm,
      language,
    } = this.props;

    const { error, accessToken } = auth;
    console.log("render");
    if (this.loaderMessage) {
      return (
        <Spin
          style={{ marginTop: "240px" }}
          size="large"
          tip={this.loaderMessage}
        >
          <div />
        </Spin>
      );
    }

    if (error) {
      return (
        <div>
          {error.message}
          <div>
            <Button onClick={authorize}>Try Again</Button>
          </div>
        </div>
      );
    }

    if (!accessToken) {
      return <AuthModal />;
    }

    const intercomPayload =
      auth && auth.idToken
        ? {
            user_id: auth.idToken["custom:forwood_uuid"],
            user_hash: auth.idToken.hmac,
          }
        : {};

    return (
      <React.Fragment>
        {/*
          Sits behind the main content and is never actually visible to the user,
          but must be in the background for our print functionality to work correctly
        */}
        <div id="print-wrapper">
          <React.Suspense fallback={<span />}>
            <Print />
          </React.Suspense>
        </div>
        <ResponsiveLayout>
          <Layout>
            <div className="map-wrapper print" id="wrapper">
              {!isDataLoaded && isLoading && <Loading />}
              <React.Suspense fallback={<Loading />}>
                {!!companyId && <MapContent ready={mapReady} />}
                {!companyId && <ModalCompanyStructure />}
              </React.Suspense>
            </div>
          </Layout>
          <AuthModal />
        </ResponsiveLayout>
        {!showAuthConfirm &&
          appConfig.intercomEnabled &&
          intercomMessenger(
            Config.reactApp.HOSTNAME,
            language,
            intercomPayload
          )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    language: state.language.locale,
    auth: state.auth,
    appConfig: state.appConfig,
    showAuthConfirm: state.auth.showAuthConfirm,
    isDataLoaded: someDataIsLoaded(state.map),
    showPrint: state.modal === "print",
    t: state.language.t,
    isLoading:
      state.map.loading["verifications"] || state.map.loading["incidents"],
    companyId: state.map.companyId,
    mapReady: state.map.mapReady,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleAuthSession(...args) {
      return handleAuthSession(dispatch, ...args);
    },
    syncLanguageConfig() {
      return syncLanguageConfig(dispatch);
    },
    authorize(...args) {
      return authorize(dispatch, ...args);
    },
    exchangeCodeForTokens(...args) {
      return exchangeCodeForTokens(dispatch, ...args);
    },
    getAppConfig(...args) {
      return getAppConfig(dispatch, ...args);
    },
  };
};

/*
 * This function is basically saying "Is there markers (either verification
 * marker or incident marker) drawn on the map?" This is to ensure the
 * <MapContent /> component (our heaviest component) only loads when there
 * is something worth showing.
 */
const someDataIsLoaded = (map) =>
  map.verifications.compliantIds.length ||
  map.verifications.nonCompliantIds.length ||
  map.incidents.pfieIds.length ||
  map.incidents.pfierIds.length ||
  map.incidents.pfizeIds.length ||
  map.incidents.pfizerIds.length ||
  map.incidents.pfiefIds.length ||
  map.incidents.pfierfIds.length ||
  null;

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
